export const PATH = {
  ROOT: "/",
  HOME: "/home",
  PROFILE: "/profile",
  LOGIN: "/login",
  PASSWORD_RESET: "/resetPassword",
  OTP: "/submitOtp",
  NEW_PASSWORD: "/newPassword",
  VALIDATOR: "/validator",
  RESET_SUCCESS: "/resetSuccess",
  ROLE: "/role_selector",
  DASH: "/dashboard",
  EMP_LIST: "/employee/list",
  EMP_ADD: "/emp/add",
  EMP_VIEW: "/emp/details",

  CUS_LIST: "/customer/list",
  CUS_ADD: "/cus/add",
  CUS_VIEW: "/cus/details",

  PV_LIST: "/vendor/product/list",
  PV_ADD: "/vendor/product/add",

  MV_LIST: "/vendor/med/list",
  MV_ADD: "/vendor/med/add",

  LAB_LIST: "/clients/labs/list",
  LAB_ADD: "/clients/labs/add",
  DOC_LIST: "/clients/doctors/list",
  DOC_ADD: "/clients/doctors/add",
  DOC_EDIT: "/clients/doctor/edit",
  DOC_VIEW: "/clients/doctor/view",

  HOS_LIST: "/clients/hospitals/list",
  HOS_ADD: "/clients/hospitals/add",
  HOS_EDIT: "/clients/hospital/edit",

  LABTEST_LIST: "/inventory/lt/list",
  LABTEST_ADD: "/inventory/lt/add",
  LABTEST_VIEW: "/inventory/lt/view",

  MED_LIST: "/inventory/med/list",
  MED_ADD: "/inventory/med/add",
  MED_VIEW: "/inventory/med/view",
  HOS_VIEW: "/clients/hospital/view",
  HOS_VIEW_SERVICE_VIEW: "/clients/hospital/services/view",
  HOS_SERVICE_LIST: "/services/hospital/list",
  HOS_SERVICE_ADD: "/services/hospital/add",
  HOS_SERVICE_CONTENT: "/services/content/map",
  HOS_SERVICE_VIEW: "/services/hospital/view",
};
export const ToastType = {
  ERROR: "error",
  SUCCESS: "success",
  INFO: "info",
  WARNING: "warning",
};
