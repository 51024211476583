import Navbar from "../components/Sidebar/SidenavBar";
import Header from "../components/Header/header";
import { AppShell } from "@mantine/core";
export default function home({ page }) {
  return (
    <AppShell
      padding="sm"
      navbar={<Navbar />}
      header={<Header />}
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      })}
    >
      {page}
    </AppShell>
  );
}
