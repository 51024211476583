import {
  IconNotes,
  IconCalendarStats,
  IconGauge,
  IconPresentationAnalytics,
  IconFileAnalytics,
  IconUsers,
  IconUserCheck,
  IconPackages,
  IconBrandAppleArcade,
  IconLock,
} from "@tabler/icons";
export default function data(role) {
  switch (role) {
    case "admin":
      return [
        { label: "Dashboard", icon: IconGauge, link: "/dashboard" },
        {
          label: "Users",
          icon: IconUsers,
          link: "#",
          links: [
            { label: "Employees", link: "/employee/list" },
            { label: "Customers", link: "/customer/list" },
          ],
        },
        {
          label: "Vendors",
          icon: IconUserCheck,
          links: [
            { label: "Products", link: "/vendor/product/list" },
            { label: "Medicine", link: "/vendor/med/list" },
          ],
        },
        {
          label: "Clients",
          icon: IconBrandAppleArcade,
          links: [
            { label: "Labs", link: "/clients/labs/list" },
            { label: "Doctors", link: "/clients/doctors/list" },
            { label: "Hospital", link: "/clients/hospitals/list" },
          ],
        },
        {
          label: "Services",
          icon: IconPackages,
          links: [
            { label: "Hospital Services", link: "/services/hospital/list" },
          ],
        },
        {
          label: "Inventory",
          icon: IconPackages,
          link: "#",
          links: [
            { label: "Lab Tests", link: "/inventory/lt/list" },
            { label: "Medicines", link: "/inventory/med/list" },
          ],
        },
      ];
  }
}
