import { PATH } from "../constants/paths";
import { lazy } from "react";

const Dashboard = lazy(() => import("../pages/Dashboard"));
const EMP_LIST = lazy(() => import("../pages/Employees/list"));
const EMP_ADD = lazy(() => import("../pages/Employees/add"));
const EMP_VIEW = lazy(() => import("../pages/Employees/view"));
const CUS_LIST = lazy(() => import("../pages/Customers/list"));
const CUS_ADD = lazy(() => import("../pages/Customers/add"));
const CUS_VIEW = lazy(() => import("../pages/Customers/view"));
const PRODUCT_VENDOR_LIST = lazy(() => import("../pages/Vendors/Product/list"));
const PRODUCT_VENDOR_ADD = lazy(() => import("../pages/Vendors/Product/add"));

const MEDICINE_VENDOR_LIST = lazy(() =>
  import("../pages/Vendors/Medicine/list")
);
const MEDICINE_VENDOR_ADD = lazy(() => import("../pages/Vendors/Medicine/add"));

const LAB_VENDOR_LIST = lazy(() =>
  import("../pages/Service Vendors/Labs/list")
);
const LAB_VENDOR_ADD = lazy(() => import("../pages/Service Vendors/Labs/add"));

const DOCTOR_LIST = lazy(() => import("../pages/Service Vendors/Doctor/list"));
const DOCTOR_ADD = lazy(() => import("../pages/Service Vendors/Doctor/add"));
const DOCTOR_EDIT = lazy(() => import("../pages/Service Vendors/Doctor/edit"));
const DOCTOR_VIEW = lazy(() => import("../pages/Service Vendors/Doctor/view"));

const LABTEST_LIST = lazy(() => import("../pages/Inventory/LabTest/list"));
const LABTEST_ADD = lazy(() => import("../pages/Inventory/LabTest/add"));
const LABTEST_EDIT = lazy(() => import("../pages/Inventory/LabTest/edit"));

const MED_LIST = lazy(() => import("../pages/Inventory/Medicine/list"));
const MED_ADD = lazy(() => import("../pages/Inventory/Medicine/add"));
const MED_EDIT = lazy(() => import("../pages/Inventory/Medicine/edit"));

const HOSPITAL_LIST = lazy(() =>
  import("../pages/Service Vendors/Hospital/list")
);
const HOSPITAL_ADD = lazy(() =>
  import("../pages/Service Vendors/Hospital/add")
);
const HOSPITAL_EDIT = lazy(() =>
  import("../pages/Service Vendors/Hospital/edit")
);
const HOSPITAL_VIEW = lazy(() =>
  import("../pages/Service Vendors/Hospital/View")
);
const HOS_SERVICE_LIST = lazy(() => import("../pages/Services/Hospital/list"));
const HOS_SERVICE_ADD = lazy(() => import("../pages/Services/Hospital/add"));
const HOS_SERVICE_CONTENT = lazy(() =>
  import("../pages/Services/Hospital/content")
);
const HOS_SERVICE_VIEW = lazy(() => import("../pages/Services/Hospital/view"));
const HOS_VIEW_SERVICE_VIEW = lazy(() =>
  import("../pages/Service Vendors/Hospital/service-list")
);
const routes = [
  {
    path: PATH.DASH,
    key: PATH.DASH,
    isProtected: true,
    page: <Dashboard />,
  },
  {
    path: PATH.EMP_LIST,
    key: PATH.EMP_LIST,
    isProtected: true,
    page: <EMP_LIST />,
  },
  {
    path: PATH.EMP_ADD,
    key: PATH.EMP_ADD,
    isProtected: true,
    page: <EMP_ADD />,
  },
  {
    path: PATH.EMP_VIEW,
    key: PATH.EMP_VIEW,
    isProtected: true,
    page: <EMP_VIEW />,
  },

  {
    path: PATH.CUS_LIST,
    key: PATH.CUS_LIST,
    isProtected: true,
    page: <CUS_LIST />,
  },
  {
    path: PATH.CUS_ADD,
    key: PATH.CUS_ADD,
    isProtected: true,
    page: <CUS_ADD />,
  },
  {
    path: PATH.CUS_VIEW,
    key: PATH.CUS_VIEW,
    isProtected: true,
    page: <CUS_VIEW />,
  },
  {
    path: PATH.PV_LIST,
    key: PATH.PV_LIST,
    isProtected: true,
    page: <PRODUCT_VENDOR_LIST />,
  },
  {
    path: PATH.PV_ADD,
    key: PATH.PV_ADD,
    isProtected: true,
    page: <PRODUCT_VENDOR_ADD />,
  },
  {
    path: PATH.MV_LIST,
    key: PATH.MV_LIST,
    isProtected: true,
    page: <MEDICINE_VENDOR_LIST />,
  },
  {
    path: PATH.MV_ADD,
    key: PATH.MV_ADD,
    isProtected: true,
    page: <MEDICINE_VENDOR_ADD />,
  },
  {
    path: PATH.LAB_LIST,
    key: PATH.LAB_LIST,
    isProtected: true,
    page: <LAB_VENDOR_LIST />,
  },
  {
    path: PATH.LAB_ADD,
    key: PATH.LAB_ADD,
    isProtected: true,
    page: <LAB_VENDOR_ADD />,
  },
  {
    path: PATH.DOC_LIST,
    key: PATH.DOC_LIST,
    isProtected: true,
    page: <DOCTOR_LIST />,
  },
  {
    path: PATH.DOC_ADD,
    key: PATH.DOC_ADD,
    isProtected: true,
    page: <DOCTOR_ADD />,
  },
  {
    path: PATH.DOC_EDIT,
    key: PATH.DOC_EDIT,
    isProtected: true,
    page: <DOCTOR_EDIT />,
  },
  {
    path: PATH.DOC_VIEW,
    key: PATH.DOC_VIEW,
    isProtected: true,
    page: <DOCTOR_VIEW />,
  },
  {
    path: PATH.LAB_ADD,
    key: PATH.LAB_ADD,
    isProtected: true,
    page: <LAB_VENDOR_ADD />,
  },
  {
    path: PATH.HOS_LIST,
    key: PATH.HOS_LIST,
    isProtected: true,
    page: <HOSPITAL_LIST />,
  },
  {
    path: PATH.HOS_ADD,
    key: PATH.HOS_ADD,
    isProtected: true,
    page: <HOSPITAL_ADD />,
  },
  {
    path: PATH.HOS_EDIT,
    key: PATH.HOS_EDIT,
    isProtected: true,
    page: <HOSPITAL_EDIT />,
  },
  {
    path: PATH.LABTEST_LIST,
    key: PATH.LABTEST_LIST,
    isProtected: true,
    page: <LABTEST_LIST />,
  },
  {
    path: PATH.LABTEST_ADD,
    key: PATH.LABTEST_ADD,
    isProtected: true,
    page: <LABTEST_ADD />,
  },
  {
    path: PATH.LABTEST_VIEW,
    key: PATH.LABTEST_VIEW,
    isProtected: true,
    page: <LABTEST_EDIT />,
  },
  {
    path: PATH.MED_LIST,
    key: PATH.MED_LIST,
    isProtected: true,
    page: <MED_LIST />,
  },
  {
    path: PATH.MED_ADD,
    key: PATH.MED_ADD,
    isProtected: true,
    page: <MED_ADD />,
  },
  {
    path: PATH.MED_VIEW,
    key: PATH.MED_VIEW,
    isProtected: true,
    page: <MED_EDIT />,
  },
  {
    path: PATH.HOS_VIEW,
    key: PATH.HOS_VIEW,
    isProtected: true,
    page: <HOSPITAL_VIEW />,
  },
  {
    path: PATH.HOS_SERVICE_LIST,
    key: PATH.HOS_SERVICE_LIST,
    isProtected: true,
    page: <HOS_SERVICE_LIST />,
  },
  {
    path: PATH.HOS_SERVICE_ADD,
    key: PATH.HOS_SERVICE_ADD,
    isProtected: true,
    page: <HOS_SERVICE_ADD />,
  },
  {
    path: PATH.HOS_SERVICE_CONTENT,
    key: PATH.HOS_SERVICE_CONTENT,
    isProtected: true,
    page: <HOS_SERVICE_CONTENT />,
  },
  {
    path: PATH.HOS_SERVICE_VIEW,
    key: PATH.HOS_SERVICE_VIEW,
    isProtected: true,
    page: <HOS_SERVICE_VIEW />,
  },
  {
    path: PATH.HOS_VIEW_SERVICE_VIEW,
    key: PATH.HOS_VIEW_SERVICE_VIEW,
    isProtected: true,
    page: <HOS_VIEW_SERVICE_VIEW />,
  },
];
export default routes;
