import React, { lazy, Suspense } from "react";
import { Route, Routes as Switch } from "react-router-dom";
import { PATH } from "../constants/paths";
import Loading from "../components/loading";
const Login = lazy(() => import("../pages/Login/userLogin"));
const LoginRole = lazy(() => import("../pages/Login/loginRole"));
const LoginPassReset = lazy(() => import("../pages/Login/loginResetPassword"));
const LoginOTP = lazy(() => import("../pages/Login/otpForm"));
const NEW_PASSWORD = lazy(() => import("../pages/Login/resetPassword"));
const RESET_SUCCESS = lazy(() => import("../pages/Login/resetsuccess"));
const EMAIL_VALIDATOR = lazy(() => import("../pages/Public/EmailValidator"));

export default function LoginRoutes() {
  return (
    <Switch>
      <Route exact path={PATH.ROOT} element={<Login />} />
      <Route
        exact
        path={PATH.LOGIN}
        element={
          <>
            <Suspense fallback={<Loading />}>
              <Login />
            </Suspense>
          </>
        }
      />
      <Route
        exact
        path={PATH.ROLE}
        element={
          <>
            <Suspense fallback={<Loading />}>
              <LoginRole />
            </Suspense>
          </>
        }
      />
      <Route
        exact
        path={PATH.PASSWORD_RESET}
        element={
          <>
            <Suspense fallback={<Loading />}>
              <LoginPassReset />
            </Suspense>
          </>
        }
      />
      <Route
        exact
        path={PATH.OTP}
        element={
          <>
            <Suspense fallback={<Loading />}>
              <LoginOTP />
            </Suspense>
          </>
        }
      />
      <Route
        exact
        path={PATH.NEW_PASSWORD}
        element={
          <>
            <Suspense fallback={<Loading />}>
              <NEW_PASSWORD />
            </Suspense>
          </>
        }
      />
      <Route
        exact
        path={PATH.RESET_SUCCESS}
        element={
          <>
            <Suspense fallback={<Loading />}>
              <RESET_SUCCESS />
            </Suspense>
          </>
        }
      />
      <Route
        exact
        path={PATH.VALIDATOR}
        element={
          <>
            <Suspense fallback={<Loading />}>
              <EMAIL_VALIDATOR />
            </Suspense>
          </>
        }
      />
    </Switch>
  );
}
